/* Define the fade-in animation */
:root {
    --primary-color: #fdac53;
    --primary-color-rgb: #fdac53;

    --chart-font-color: #131313;
    // Delta Colors
    --delta-low: 255, 69, 69; /* Red */
    --delta-low-medium: 246, 119, 71; /* Orange */
    --delta-high-medium: 255, 188, 69; /* Yellow */
    --delta-high: 45, 201, 90; /* Green */
    --neutral: 0, 0, 0; /* Gray */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(
            30px
        ); /* Optional: adds a slight vertical movement */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Apply fade-in animation to the .fade-in-card class */
.fade-in-card {
    animation: fadeIn 0.8s linear forwards; /* Adjust the duration as needed */
    opacity: 0; /* Ensure that the card starts off as invisible */
}
.preloader .wave {
    animation: wave 1s ease-in-out infinite;
    animation-delay: calc(var(--i) * 0.1s);
}

app-progress-loader {
    .progress-loader {
        display: inline-block;
        position: relative;
        width: 72px;
        height: 72px;
        margin: auto;
        &__drop,
        &__circle1,
        &__circle2 {
            position: absolute;
            inset: 0px;
            margin: auto;
            border-radius: 50%;
            opacity: 0;
            box-sizing: border-box;
        }
        &__drop {
            background: var(--primary-color);
            width: 18px;
            height: 18px;
            animation: 1.5s ease-in infinite drop;
        }
        &__circle1 {
            width: 72px;
            height: 72px;
            border: 4.5px solid var(--primary-color);
            animation: circle1 1.5s ease-out infinite;
        }

        &__circle2 {
            width: 72px;
            height: 72px;
            border: 5.53846154px solid var(--primary-color);
            animation: circle2 1.5s ease-out infinite;
        }
    }
    // Light variation
    .progress-loader--light {
        .progress-loader__drop,
        .progress-loader__circle1,
        .progress-loader__circle2 {
            background: var(--primary-100);
        }
    }

    // Dark variation
    .progress-loader--dark {
        .progress-loader__drop,
        .progress-loader__circle1,
        .progress-loader__circle2 {
            background: var(--primary-0);
        }
    }
}
@keyframes wave {
    0% {
        transform: translateY(0);
        opacity: 0.2;
    }
    20% {
        transform: translateY(-20px);
        opacity: 0.3;
    }
    40%,
    100% {
        transform: translateY(0px);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes circle1 {
    000% {
        transform: scale(0);
        opacity: 1;
    }
    020% {
        transform: scale(0);
        opacity: 1;
    }
    080% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes circle2 {
    000% {
        transform: scale(0);
        opacity: 1;
    }
    040% {
        transform: scale(0);
        opacity: 1;
    }
    090% {
        transform: scale(0.8);
        opacity: 0;
    }
}

@keyframes drop {
    000% {
        transform: scale(1);
        opacity: 0;
    }
    005% {
        opacity: 0.8;
    }
    028% {
        transform: scale(0);
        opacity: 1;
    }
    030% {
        transform: scale(0);
        opacity: 1;
    }
    040% {
        transform: scale(0);
        opacity: 1;
    }
    090% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
