@media (max-width: 1280px) {
    app-number-chart {
        flex-basis: calc(18% - 16px) !important;
    }
}
@media (max-width: 1140px) {
    app-number-chart {
        flex-basis: calc(18% - 16px) !important;
    }
}
@media (max-width: 950px) {
    app-number-chart {
        flex-basis: calc(33.3% - 16px) !important;
    }
}
@media (max-width: 768px) {
    app-number-chart {
        flex-basis: 100% !important;
    }
}
