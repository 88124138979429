/* You can add global styles to this file, and also import other style files */
/* @import "@angular/material/prebuilt-themes/indigo-pink.css"; */
* {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    text-decoration: none;
    color: inherit;
    text-decoration: none;
}
*:before,
*:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
html,
body {
    height: 100%;
}
a {
    color: inherit;
    text-decoration: none;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    background-color: #eeeeee;
}

.green-snackbar button {
    color: red;
}

.custom-snackbar {
    // position: absolute;
    height: auto;
    margin-top: 75px !important;
    margin-right: 5px !important;
    --mdc-snackbar-container-color: #131313;
    --mdc-snackbar-supporting-text-color: #fdac53;
    max-width: 350px;
    font-size: medium;
}

.iti {
    display: block !important;
    margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #c7cace;
    margin-top: -1px;
}

.iti .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: 0;
    width: 244px;
    max-height: 170px;
}

.iti__flag-container.open + input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.iti .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
}

.iti .search-container input:focus {
    outline: none;
}

@media screen and (max-width: 479px) {
    .iti .iti__country-list {
        width: 88.3vw;
    }
}
ngx-intl-tel-input input {
    height: 44px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    text-align: left;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
    border-bottom: 2px solid #c0392b;
}
ngx-intl-tel-input.ng-invalid.ng-untouched input {
    border-bottom: 2px solid #c0392b;
}
ngx-intl-tel-input input:hover {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
    outline: none !important;
    border-color: #3498db;
    box-shadow: none;
}

ngx-intl-tel-input input::placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
    background-color: #e5eaf1;
}
